import { IEnvironment } from './i-environment';

export const environment: IEnvironment = {
  production: true,
  baseURL: '/api/v1/',
  logLevel: "information",
  logType: "backend",
  salt: 'QTZ4blFoYno0VngySHVHbDRsWHdaNVUySThpemlMUkZuaFA1ZU5mSVJ2UQ',
  serverURL: '',
  maxProfileFieldsCount: 50,
  envName: 'eu-prod',
  translationsUrlPrefix: 'https://i18n.ownid-eu.com/',
  translationsUrlSuffix: '/web-app.json',
  allowMocks: false,
  logRocket: {
    'passwordless.carrefour.be': '8aznfr/webapp-carrefour',
    'passwordless.aem.carrefour.be': '8aznfr/webapp-carrefour',
  }
};
